import React from "react"
import Header from "../components/Header";
import Resume from "../components/Resume"

function ResumePage() {
  return (
    <div>
      <Header />
      <Resume />
    </div>
  );
}

export default ResumePage;

